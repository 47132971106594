import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDoc, doc, setDoc, getDocs, collection } from 'firebase/firestore';
import { db, auth } from '../firebase';
import * as XLSX from 'xlsx';
import '../styles/ExamDashboard.css';

const ExamDashboard = () => {
  const { examId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer, setTimer] = useState(0);
  const [duration, setDuration] = useState(0);
  const [answers, setAnswers] = useState({});
  const [skippedQuestions, setSkippedQuestions] = useState(new Set());
  const [examData, setExamData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('Anonymous');
  const [examAvailable, setExamAvailable] = useState(false);
  const [alertTimeout, setAlertTimeout] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false); 

  const [startTime, setStartTime] = useState(Date.now());
  const [timeSpent, setTimeSpent] = useState({});


  const questionsRef = useRef(questions);
  const answersRef = useRef(answers);

  useEffect(() => {
    questionsRef.current = questions;
  }, [questions]);

  useEffect(() => {
    answersRef.current = answers;
  }, [answers]);

  useEffect(() => {
    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
        setUserName(user.displayName || 'Anonymous');

        const blockedUsersSnapshot = await getDocs(collection(db, 'blockedUsers'));
        const blockedUsers = blockedUsersSnapshot.docs.map(doc => doc.id);
        if (blockedUsers.includes(user.uid)) {
           setIsBlocked(true);
           alert('You have been blocked due to suspicious activities. Redirecting to the alert page.');
           navigate('/alert');

        }
      } else {
        navigate('/login');
      }
    };

    fetchUserId();
  }, [navigate]);

  useEffect(() => {
    if (isBlocked) return;
    const fetchExam = async () => {
      try {
        const examDoc = await getDoc(doc(db, 'exams', examId));
        if (examDoc.exists()) {
          const examData = examDoc.data();
          setExamData(examData);
          setDuration(parseInt(examData.duration, 10));
          setTimer(parseInt(examData.duration, 10) * 60);

          const currentTime = new Date();
          const validFrom = new Date(examData.validFrom);
          const validTill = new Date(examData.validTill);

          if (currentTime >= validFrom && currentTime <= validTill) {
            setExamAvailable(true);
          } else {
            setExamAvailable(false);
          }

          const response = await fetch(examData.excelFileUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const questionsData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          const formattedQuestions = questionsData.slice(1).map((row, index) => ({
            id: index,
            question: row[0] ? row[0].toString() : '',
            optionA: row[1] ? row[1].toString() : '',
            optionB: row[2] ? row[2].toString() : '',
            optionC: row[3] ? row[3].toString() : '',
            optionD: row[4] ? row[4].toString() : '',
            answer: row[5] ? row[5].toString() : '',
          }));

          const numQuestions = parseInt(examData.numQuestions, 10);
          const shuffledQuestions = formattedQuestions.sort(() => 0.5 - Math.random());
          const selectedQuestions = shuffledQuestions.slice(0, numQuestions);

          setQuestions(selectedQuestions);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching exam:', error);
      }
    };

    fetchExam();
  }, [examId, isBlocked]);

  useEffect(() => {
    if (isBlocked) return;

    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer <= 0) {
          clearInterval(interval);
          handleSubmit(); // Automatically submit when time is up
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isBlocked]);

  useEffect(() => {
    if (isBlocked) return;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setAlertTimeout(setTimeout(() => {
          alert('You have been blocked due to suspicious activities. You will be redirected shortly.');
          logBlockedUser();
          navigate('/alert');
        }));
      } else {
        if (alertTimeout) {
          clearTimeout(alertTimeout);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (alertTimeout) {
        clearTimeout(alertTimeout);
      }
    };
  }, [navigate, alertTimeout, isBlocked]);
  

  const logBlockedUser = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await setDoc(doc(db, 'blockedUsers', user.uid), {
          userId: user.uid,
          userName: user.displayName || 'Anonymous',
          email: user.email,
          examId,
          timestamp: new Date(),
        });
      }
    } catch (error) {
      console.error('Error logging blocked user:', error);
    }
  };

  const handlePrevious = () => {
    // Block the user when the Previous button is pressed
    /*alert('You have been blocked due to suspicious activities (using the Previous button). You will be redirected shortly.');
    logBlockedUser();
    navigate('/alert');*/
  };
  

  const updateTimeSpent = (index) => {
    const endTime = Date.now();
    const timeDiff = (endTime - startTime) / 1000; // time in seconds
  
    setTimeSpent((prevTimeSpent) => ({
      ...prevTimeSpent,
      [index]: (prevTimeSpent[index] || 0) + timeDiff,
    }));
    setStartTime(Date.now());
  };
  
  const handleNext = () => {
    updateTimeSpent(currentQuestionIndex);
    setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, questions.length - 1));
  };
  
  const handleSkip = () => {
    updateTimeSpent(currentQuestionIndex);
    setSkippedQuestions((prevSkipped) => new Set(prevSkipped.add(currentQuestionIndex)));
    setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, questions.length - 1));
  };
  
  const handleOptionChange = (questionIndex, selectedOption) => {
    updateTimeSpent(questionIndex);
    setAnswers((prevAnswers) => {
      const currentAnswer = prevAnswers[questionIndex];
      const newAnswers = {
        ...prevAnswers,
        [questionIndex]: currentAnswer === selectedOption ? null : selectedOption,
      };
      if (newAnswers[questionIndex] === null) {
        setSkippedQuestions((prevSkipped) => {
          const updatedSkipped = new Set(prevSkipped);
          updatedSkipped.delete(questionIndex);
          return updatedSkipped;
        });
      }
      return newAnswers;
    });
  };
  

  const handleSubmit = async () => {
    try {
      if (isBlocked) {
        alert('You are blocked from taking this exam.');
        return;
      }
  
      const user = auth.currentUser;
      const examDoc = await getDoc(doc(db, 'exams', examId));
      const examData = examDoc.exists() ? examDoc.data() : null;
  
      // Log the time spent on the last question
      updateTimeSpent(currentQuestionIndex);
  
      const result = questionsRef.current.map((question, index) => {
        const selectedOption = answersRef.current.hasOwnProperty(index) ? answersRef.current[index] : null;
        const correctAnswer = question.answer;
        const selectedAnswer = selectedOption ? question[`option${selectedOption}`] : null;
  
        return {
          questionId: question.id,
          questionText: question.question,
          selectedOption,
          correctAnswer,
          selectedAnswer,
          timeSpent: timeSpent[index] || 0, // Add the time spent on this question
        };
      });
  
      const score = result.reduce((total, item) => {
        if (item.selectedOption === null) {
          return total; // Skipped questions don't add to the score
        }
        if (item.selectedAnswer === item.correctAnswer) {
          return total + 1;
        }
        return total;
      }, 0);
  
      const docData = {
        studentId: user?.uid || 'unknown',
        examId,
        result,
        score,
        studentName: user?.displayName || 'Anonymous',
        course: examData?.courseId || 'Unknown Course',
        subject: examData?.subjectId || 'Unknown Subject',
        marksObtained: score,
        timeSpentPerQuestion: timeSpent, // Store the time spent on each question
        submittedAt: new Date(),
      };
  
      await setDoc(doc(db, 'results', `${examId}_${user?.uid}`), docData);
  
      alert('Exam submitted successfully!');
      navigate('/student-dashboard');
    } catch (error) {
      console.error('Error submitting exam:', error);
    }
  };
  
  if (isBlocked) {
    return <div>Loading...</div>; // Render nothing if user is blocked
    }

  const getQuestionStatus = (index) => {
    if (skippedQuestions.has(index)) {
      return 'skipped';
    }
    if (answers.hasOwnProperty(index)) {
      return 'attempted';
    }
    return 'pending';
  };

  /*const handleQuestionCircleClick = (index) => {
    if (index < currentQuestionIndex) {
      // Block the user if they attempt to go to a previous question via the sidebar
      alert('You have been blocked due to suspicious activities (attempting to access previous question). You will be redirected shortly.');
      logBlockedUser();
      navigate('/alert');
    } else {
      // Update time spent on the current question
      updateTimeSpent(currentQuestionIndex);
      setCurrentQuestionIndex(index);
    }
  }; */

  
  const handleQuestionCircleClick = (index) => {
    if (index <= currentQuestionIndex) {
      // Do nothing when clicking on previous questions
      return;
    } else {
      // Update time spent on the current question before moving forward
      updateTimeSpent(currentQuestionIndex);
      setCurrentQuestionIndex(index);
    }
  };
  
  

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="exam-dashboard">
      <div className="sidebar">
  <div className="question-status">
    {questions.map((_, index) => (
      <div
        key={index}
        className={`question-circle ${getQuestionStatus(index)}`}
        onClick={() => handleQuestionCircleClick(index)}
      >
        {index + 1}
      </div>
    ))}
  </div>
</div>

      <div className="exam-content">
        <h2>Exam Dashboard</h2>
        {examAvailable ? (
          <>
            <div className="timer">
              Time left: {Math.floor(timer / 60)}:
              {timer % 60 < 10 ? '0' : ''}
              {timer % 60} minutes
            </div>
            {currentQuestion && (
              <div className="question-container">
                <h3>{currentQuestion.question}</h3>
                <div className="options">
                  <label>
                    <input
                      type="checkbox"
                      name={`option-${currentQuestionIndex}`}
                      checked={answers[currentQuestionIndex] === 'A'}
                      onChange={() => handleOptionChange(currentQuestionIndex, 'A')}
                    />
                    {currentQuestion.optionA}
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name={`option-${currentQuestionIndex}`}
                      checked={answers[currentQuestionIndex] === 'B'}
                      onChange={() => handleOptionChange(currentQuestionIndex, 'B')}
                    />
                    {currentQuestion.optionB}
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name={`option-${currentQuestionIndex}`}
                      checked={answers[currentQuestionIndex] === 'C'}
                      onChange={() => handleOptionChange(currentQuestionIndex, 'C')}
                    />
                    {currentQuestion.optionC}
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name={`option-${currentQuestionIndex}`}
                      checked={answers[currentQuestionIndex] === 'D'}
                      onChange={() => handleOptionChange(currentQuestionIndex, 'D')}
                    />
                    {currentQuestion.optionD}
                  </label>
                </div>
                <div className="navigation-buttons">
                  <button
                    className="prev-button"
                    onClick={handlePrevious}
                    disabled={currentQuestionIndex === 0}
                  >
                    Previous
                  </button>
                  <button className="skip-button" onClick={handleSkip}>
                    Skip
                  </button>
                  <button
                    className="next-button"
                    onClick={handleNext}
                    disabled={currentQuestionIndex === questions.length - 1}
                  >
                    Next
                  </button>
                </div>
                {currentQuestionIndex === questions.length - 1 && (
                  <div className="submit-button-container">
                    <button className="submit-button" onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <p>This exam is currently not available.</p>
        )}
      </div>
    </div>
  );
};

export default ExamDashboard; 

