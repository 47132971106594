import React from 'react';
import '../styles/InstructionsModal.css';

const InstructionsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>Exam Dashboard Instructions</h2>
        <ol>
          <li><strong>Preparation:</strong>
            <ul>
              <li>Ensure you are using a stable internet connection before starting the exam.</li>
              <li>Log in with your registered account credentials. If you are not logged in, you will be redirected to the login page.</li>
              <li>Make sure you have a quiet and comfortable environment to focus on the exam.</li>
            </ul>
          </li>
          <li><strong>Exam Availability:</strong>
            <ul>
              <li>The exam will be available only during the specified time window. If you try to access it outside this period, the exam will not be available.</li>
              <li>Please ensure you start the exam within the valid period. Starting early or late could result in missing the exam.</li>
            </ul>
          </li>
          <li><strong>Navigating Through Questions:</strong>
            <ul>
              <li>You can navigate between questions using the "Next" and "Skip" buttons.</li>
              <li><u>Important: Once you move past a question, you cannot go back to it. Any attempt to do so will result in you being blocked from the exam.</u></li>
              
            </ul>
          </li>
          <li><strong>Answering Questions:</strong>
            <ul>
              <li>Each question offers multiple options (A, B, C, D). Select the checkbox corresponding to your answer.</li>
              <li>You can change your answer before moving to the next question. However, once you move past a question, it is final.</li>
              <li>Be sure to manage your time effectively. The timer at the top will show how much time you have left.</li>
            </ul>
          </li>
          <li><strong>Time Management:</strong>
            <ul>
              <li>The exam has a set duration. A timer will be displayed showing the time remaining.</li>
              <li>The exam will automatically submit when the timer runs out.</li>
              <li>Each question has a time tracker to monitor how much time you spent on it.</li>
            </ul>
          </li>
          <li><strong>Suspicious Activity:</strong>
            <ul>
              <li>Warning: Any suspicious activity, such as trying to navigate to a previous question or attempting to leave the exam window, will result in being blocked from the exam.</li>
              <li>If blocked, you will be redirected to an alert page, and your attempt will be logged.</li>
            </ul>
          </li>
          <li><strong>Submitting the Exam:</strong>
            <ul>
              <li>Once you reach the last question, you will have the option to submit the exam.</li>
              <li>Before submitting, ensure you have reviewed all your answers, especially any skipped questions.</li>
              <li>After submission, your results will be saved, and you will be redirected to the student dashboard.</li>
            </ul>
          </li>
        </ol>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default InstructionsModal;
