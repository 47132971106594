import React from 'react';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
    <div className="homepage">
      <h1>IMTS INSTITUTE</h1>
      <div className="login-options">
        <a href="/admin-login">Admin Login</a>
        <a href="/student-login">Student Login</a>
      </div>
    </div>
  );
}

export default HomePage;
