// src/components/Header.js
import React from 'react';
import '../styles/header.css';
import logo from '../assets/logo.png'; // Make sure to place your logo image in the 'src/assets' directory

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="IMTS Institute Logo" className="logo" />
      </div>
    </header>
  );
};

export default Header;
