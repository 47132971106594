

/*import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase'; // Adjust the path

const EditExcel = () => {
  const location = useLocation();
  const { fileUrl } = location.state;
  const [fileData, setFileData] = useState([]);
  const [workbook, setWorkbook] = useState(null);

  useEffect(() => {
    const fetchFile = async () => {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        setWorkbook(workbook);
        const sheetsData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
        setFileData(sheetsData);
      };

      reader.readAsArrayBuffer(blob);
    };

    fetchFile();
  }, [fileUrl]);

  const handleCellChange = (rowIndex, cellIndex, value) => {
    const updatedData = [...fileData];
    updatedData[rowIndex][cellIndex] = value;
    setFileData(updatedData);
  };

  const handleSaveChanges = async () => {
    const worksheet = XLSX.utils.aoa_to_sheet(fileData);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');

    const wbout = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });

    // Generate a new file name or use the existing one
    const newFileName = `edited_${fileUrl.split('/').pop()}`;
    const fileRef = ref(storage, `examFiles/${newFileName}`);
    const newFile = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    await uploadBytes(fileRef, newFile);
    alert('Changes saved successfully.');
  };

  return (
    <div className="edit-excel">
      <h2>Edit Excel File</h2>
      {fileData.length > 0 ? (
        <table>
          <tbody>
            {fileData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>
                    <input
                      type="text"
                      value={cell}
                      onChange={(e) => handleCellChange(rowIndex, cellIndex, e.target.value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading data...</p>
      )}
      <button onClick={handleSaveChanges} className="save-button">Save Changes</button>
    </div>
  );
};

export default EditExcel; */



import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase'; // Adjust path as needed
import '../styles/EditExcel.css';

const EditExcel = () => {
  const location = useLocation();
  const { fileUrl } = location.state;
  const [fileData, setFileData] = useState([]);
  const [workbook, setWorkbook] = useState(null);

  useEffect(() => {
    const fetchFile = async () => {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        setWorkbook(workbook);
        const sheetsData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
        setFileData(sheetsData);
      };

      reader.readAsArrayBuffer(blob);
    };

    fetchFile();
  }, [fileUrl]);

  const handleCellChange = (rowIndex, cellIndex, value) => {
    const updatedData = [...fileData];
    updatedData[rowIndex][cellIndex] = value;
    setFileData(updatedData);
  };

  const handleSaveChanges = async () => {
    const worksheet = XLSX.utils.aoa_to_sheet(fileData);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');

    const wbout = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
    const newFileName = `edited_${fileUrl.split('/').pop()}`;
    const fileRef = ref(storage, `examFiles/${newFileName}`);
    const newFile = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    await uploadBytes(fileRef, newFile);
    alert('Changes saved successfully.');
  };

  return (
    <div className="edit-excel">
      
      <div className="main-content">
        <h2>Edit Excel File</h2>
        {fileData.length > 0 ? (
          <table>
            <tbody>
              {fileData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      <input
                        type="text"
                        value={cell}
                        onChange={(e) => handleCellChange(rowIndex, cellIndex, e.target.value)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Loading data...</p>
        )}
        <button onClick={handleSaveChanges} className="save-button">Save Changes</button>
      </div>
      <footer>
        <p>Footer Content</p>
      </footer>
    </div>
  );
};

export default EditExcel;
