/*import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this path is correct
import '../styles/StudentList.css';

const StudentList = () => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      const studentCollection = collection(db, 'studentDetails');
      const studentSnapshot = await getDocs(studentCollection);
      const studentList = studentSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudents(studentList);
    };

    fetchStudents();
  }, []);

  const handleDelete = async (studentId, studentName) => {
    if (window.confirm(`Are you sure you want to delete the student ${studentName}?`)) {
      try {
        await deleteDoc(doc(db, 'studentDetails', studentId));
        setStudents(students.filter(student => student.id !== studentId));
        alert('Student deleted successfully');
      } catch (e) {
        console.error('Error deleting student: ', e);
        alert('Error deleting student. Please try again.');
      }
    }
  };

  return (
    <div className="student-list">
      <h2>Registered Students</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Course</th>
            <th>Email</th>
            <th>Date of Birth</th>
            <th>Mobile Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.id}>
              <td>{student.name}</td>
              <td>{student.course}</td>
              <td>{student.email}</td>
              <td>{student.dob}</td>
              <td>{student.phone}</td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(student.id, student.name)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentList; */

import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this path is correct
import '../styles/StudentList.css';

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [sortOption, setSortOption] = useState(''); // State for sorting option

  useEffect(() => {
    const fetchStudents = async () => {
      const studentCollection = collection(db, 'studentDetails');
      const studentSnapshot = await getDocs(studentCollection);
      const studentList = studentSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudents(studentList);
    };

    fetchStudents();
  }, []);

  // Handle sorting students
  const handleSort = (option) => {
    setSortOption(option);
    let sortedStudents = [...students];
    if (option === 'name') {
      sortedStudents.sort((a, b) => a.name.localeCompare(b.name));
    } else if (option === 'course') {
      sortedStudents.sort((a, b) => a.course.localeCompare(b.course));
    }
    setStudents(sortedStudents);
  };

  const handleDelete = async (studentId, studentName) => {
    if (window.confirm(`Are you sure you want to delete the student ${studentName}?`)) {
      try {
        await deleteDoc(doc(db, 'studentDetails', studentId));
        setStudents(students.filter(student => student.id !== studentId));
        alert('Student deleted successfully');
      } catch (e) {
        console.error('Error deleting student: ', e);
        alert('Error deleting student. Please try again.');
      }
    }
  };

  return (
    <div className="student-list">
      <h2>Registered Students</h2>
      
      {/* Sort Options */}
      <div className="sort-options">
        <label htmlFor="sort">Sort By: </label>
        <select id="sort" value={sortOption} onChange={(e) => handleSort(e.target.value)}>
          <option value="">Select</option>
          <option value="name">Name</option>
          <option value="course">Course</option>
        </select>
      </div>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Course</th>
            <th>Email</th>
            <th>Date of Birth</th>
            <th>Mobile Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.id}>
              <td>{student.name}</td>
              <td>{student.course}</td>
              <td>{student.email}</td>
              <td>{student.dob}</td>
              <td>{student.phone}</td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(student.id, student.name)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentList;

