









import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, listAll, getDownloadURL, deleteObject, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase'; // Adjust the import path as needed
import * as XLSX from 'xlsx';
import '../styles/QuestionList.css'; // Import the CSS for styling

const PAGE_SIZE = 10; // Number of rows per page

const QuestionList = () => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileName, setUploadFileName] = useState('');
  const [viewingFile, setViewingFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileToUpdate, setFileToUpdate] = useState(null);

  const navigate = useNavigate();

  const hiddenFileInput = useRef(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const listRef = ref(storage, 'examFiles/');
        const res = await listAll(listRef);

        const fileUrls = await Promise.all(
          res.items.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return { name: itemRef.name, url, ref: itemRef };
          })
        );

        setFiles(fileUrls);
      } catch (error) {
        console.error('Error fetching files:', error);
        setError('Error fetching files.');
      }
    };

    fetchFiles();
  }, []);

  const handleDelete = async (fileRef) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      try {
        await deleteObject(fileRef);
        setFiles((prevFiles) => prevFiles.filter((file) => file.ref !== fileRef));
      } catch (error) {
        console.error('Error deleting file:', error);
        setError('Error deleting file.');
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadFile(file);
    setUploadFileName(file.name);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!uploadFile) {
      setError('Please select a file to upload.');
      return;
    }

    try {
      const storageRef = ref(storage, `examFiles/${uploadFile.name}`);
      await uploadBytes(storageRef, uploadFile);
      const fileUrl = await getDownloadURL(storageRef);

      // Update the files list
      setFiles((prevFiles) => [
        ...prevFiles,
        { name: uploadFile.name, url: fileUrl, ref: storageRef },
      ]);

      // Reset state after upload
      setUploadFile(null);
      setUploadFileName('');
      setError(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Error uploading file.');
    }
  };

  const handleView = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetNames = workbook.SheetNames;
        const sheetsData = sheetNames.map(sheetName => XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 }));

        setFileData(sheetsData);
        setViewingFile(fileUrl);
        setCurrentPage(1); // Reset to the first page
      };

      reader.readAsArrayBuffer(blob);
    } catch (error) {
      console.error('Error viewing file:', error);
      setError('Error viewing file.');
    }
  };

  const handleAdd = (fileUrl) => {
    // Placeholder for edit functionality
    console.log('Handle button clicked for:', fileUrl);
  };

  const handleFileChangeForUpload = async (e, fileName) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const storageRef = ref(storage, `examFiles/${file.name}`);
      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);

      // Update the files list
      setFiles((prevFiles) => [
        ...prevFiles.filter((f) => f.name !== fileName),
        { name: file.name, url: fileUrl, ref: storageRef },
      ]);

      setError(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Error uploading file.');
    }
  };

  // Trigger hidden file input when the "Upload" button is clicked
  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleEdit = (fileUrl) => {
    // Navigate to the edit page and pass the fileUrl in the state
    navigate('/edit-excel', { state: { fileUrl } });
  };
  

  const getPaginatedData = (data, page) => {
    const start = (page - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    return data.slice(start, end);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(fileData.flat().length / PAGE_SIZE);

  return (
    <div className="question-list">
      <h2>Question Papers</h2>
      {error && <div className="error-message">{error}</div>}
      
      <form onSubmit={handleUpload} className="upload-form">
        <div className="form-group">
          <label htmlFor="uploadFile">Upload Question Paper:</label>
          <input
            type="file"
            id="uploadFile"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            required
          />
          <button type="submit" className="upload-button">Upload</button>
        </div>
      </form>

      <table>
        <thead>
          <tr>
            <th>Question Paper</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {files.length > 0 ? (
            files.map((file, index) => (
              <tr key={index}>
                <td>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name}
                  </a>
                </td>
                <td className="action-buttons">
                  <button onClick={() => handleDelete(file.ref)} className="delete-button">
                    Delete
                  </button>
                  <span className="separator"> / </span>
                  <button onClick={() => handleView(file.url)} className="view-button">
                    View
                  </button>
                  <span className="separator"> / </span>
                  <button onClick={() => handleEdit(file.url)} className="edit-button">
                    Edit
                  </button>
                  <span className="separator"> / </span>
                  <button onClick={handleUploadClick} className="upload-button">
                    Upload
                  </button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => handleFileChangeForUpload(e, file.name)}
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                  />  

                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No question papers available.</td>
            </tr>
          )}
        </tbody>
      </table>

      {viewingFile && (
        <div className="file-viewer">
          <h3>Viewing File: {viewingFile}</h3>
          {fileData.length > 0 && (
            <>
              {getPaginatedData(fileData.flat(), currentPage).length > 0 ? (
                <div>
                  <table>
                    <tbody>
                      {getPaginatedData(fileData.flat(), currentPage).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination-controls">
                    <button 
                      onClick={() => handlePageChange(currentPage - 1)} 
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button 
                      onClick={() => handlePageChange(currentPage + 1)} 
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <p>No data available to display.</p>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionList; 