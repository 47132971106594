import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAjoE_bLUefi3mMNhDK3x5Yf0bXXPmVVNE",
  authDomain: "imts-exam-portal-e6244.firebaseapp.com",
  projectId: "imts-exam-portal-e6244",
  storageBucket: "imts-exam-portal-e6244.appspot.com",
  messagingSenderId: "1046846057216",
  appId: "1:1046846057216:web:7cd10bec7715066f25e1e5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firebase Storage
const storage = getStorage(app);

// Export
export {
  db,
  app,
  auth,
  storage,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
};