import React, { useState, useEffect } from 'react';
import { collection, getDocs, where, query, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import '../styles/AddNewExam.css';

const AddNewExam = () => {
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [examDuration, setExamDuration] = useState('');
  const [numQuestions, setNumQuestions] = useState('');
  const [eligibleStudents, setEligibleStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileName, setExcelFileName] = useState('');
  const [examDate, setExamDate] = useState('');
  const [validFrom, setValidFrom] = useState('');
  const [validTill, setValidTill] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const courseCollection = await getDocs(collection(db, 'courses'));
        const courseList = courseCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCourses(courseList);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleCourseChange = async (e) => {
    const courseId = e.target.value;
    setSelectedCourse(courseId);

    try {
      const q = query(collection(db, 'subjects'), where('courseId', '==', courseId));
      const subjectSnapshot = await getDocs(q);
      const subjectList = subjectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSubjects(subjectList);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }

    try {
      const eligibleStudentsQuery = query(collection(db, 'studentDetails'), where('courseId', '==', courseId));
      const eligibleStudentsSnapshot = await getDocs(eligibleStudentsQuery);
      const studentsList = eligibleStudentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEligibleStudents(studentsList);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleExamDurationChange = (e) => {
    setExamDuration(e.target.value);
  };

  const handleNumQuestionsChange = (e) => {
    setNumQuestions(e.target.value);
  };

  const handleStudentSelection = (e) => {
    const studentId = e.target.value;
    setSelectedStudents(prevSelected => 
      prevSelected.includes(studentId) 
        ? prevSelected.filter(id => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExcelFile(file);
    setExcelFileName(file.name);
  };

  const handleExamDateChange = (e) => {
    setExamDate(e.target.value);
  };

  const handleValidFromChange = (e) => {
    setValidFrom(e.target.value);
  };

  const handleValidTillChange = (e) => {
    setValidTill(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCourse || !selectedSubject || !examDuration || !numQuestions || !excelFile || selectedStudents.length === 0 || !examDate || !validFrom || !validTill) {
      setError('Please fill out all fields and select at least one student.');
      return;
    }

    try {
      const storageRef = ref(storage, `examFiles/${excelFile.name}`);
      await uploadBytes(storageRef, excelFile);
      const fileUrl = await getDownloadURL(storageRef);

      await addDoc(collection(db, 'exams'), {
        courseId: selectedCourse,
        subjectId: selectedSubject,
        duration: examDuration,
        numQuestions: parseInt(numQuestions, 10),
        students: selectedStudents,
        excelFileName: excelFileName,
        excelFileUrl: fileUrl,
        examDate: examDate,
        validFrom: validFrom,
        validTill: validTill,
      });

      alert('Exam created successfully!');
      // Reset form after successful submission
      setSelectedCourse('');
      setSelectedSubject('');
      setExamDuration('');
      setNumQuestions('');
      setSelectedStudents([]);
      setExcelFile(null);
      setExcelFileName('');
      setExamDate('');
      setValidFrom('');
      setValidTill('');
      setError(null); // Clear any existing errors
    } catch (error) {
      console.error('Error creating exam:', error);
      setError('Error creating exam: ' + error.message);
    }
  };

  return (
    <div className="add-new-exam-page">
      <h2>Add New Exam</h2>
      <form className="add-new-exam-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="course">Course:</label>
          <select id="course" value={selectedCourse} onChange={handleCourseChange} required>
            <option value="">Select a course</option>
            {courses.map(course => (
              <option key={course.id} value={course.id}>{course.courseName}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject:</label>
          <select id="subject" value={selectedSubject} onChange={handleSubjectChange} required>
            <option value="">Select a subject</option>
            {subjects.map(subject => (
              <option key={subject.id} value={subject.id}>{subject.subjectName}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="duration">Exam Duration (minutes):</label>
          <input type="number" id="duration" value={examDuration} onChange={handleExamDurationChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="numQuestions">Number of Questions:</label>
          <input type="number" id="numQuestions" value={numQuestions} onChange={handleNumQuestionsChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="examDate">Exam Date:</label>
          <input type="date" id="examDate" value={examDate} onChange={handleExamDateChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="validFrom">Valid From:</label>
          <input type="datetime-local" id="validFrom" value={validFrom} onChange={handleValidFromChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="validTill">Valid Till:</label>
          <input type="datetime-local" id="validTill" value={validTill} onChange={handleValidTillChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="students">Eligible Students:</label>
          <div className="students-list">
            {eligibleStudents.map(student => (
              <div key={student.id} className="student-item">
                <input
                  type="checkbox"
                  value={student.id}
                  checked={selectedStudents.includes(student.id)}
                  onChange={handleStudentSelection}
                />
                <span>{student.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="excelFile">Upload Excel File:</label>
          <input type="file" id="excelFile" accept=".xlsx, .xls" onChange={handleFileChange} required />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit" className="add-exam-button">Add Exam</button>
      </form>
    </div>
  );
};

export default AddNewExam;  





