import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this path is correct
import '../styles/DetailedResult.css';

const DetailedResult = ({ studentId, examId }) => {
  const [detailedResult, setDetailedResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDetailedResult = async () => {
      try {
        // Query the results collection to get the specific student's result for the given exam
        const resultsCollection = collection(db, 'results');
        const q = query(resultsCollection, where('studentId', '==', studentId), where('examId', '==', examId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const resultData = querySnapshot.docs[0].data();
          setDetailedResult(resultData);
        } else {
          setError('No detailed result found for this student.');
        }
      } catch (error) {
        setError('Error fetching detailed result: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDetailedResult();
  }, [studentId, examId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!detailedResult) {
    return <div>No result found.</div>;
  }

  return (
    <div className="detailed-result">
      <h2>Detailed Result </h2>
      <table>
        <thead>
          <tr>
            <th>Question ID</th>
            <th>Question</th>
            <th>Correct Answer</th>
            <th>Selected Answer</th>
            <th>Status</th>
            <th>Time Spent (Seconds)</th>
          </tr>
        </thead>
        <tbody>
          {detailedResult.result.map((questionResult, index) => (
            <tr key={index}>
              <td>{questionResult.questionId}</td>
              <td>{questionResult.questionText || 'Question text not available'}</td> {/* Assuming you have a question text */}
              <td>{questionResult.correctAnswer}</td>
              <td>{questionResult.selectedAnswer}</td>
              <td>
                {questionResult.selectedAnswer === questionResult.correctAnswer
                  ? 'Correct'
                  : questionResult.selectedAnswer
                  ? 'Incorrect'
                  : 'Skipped'}
              </td>
              <td>{questionResult.timeSpent}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="result-summary">
        <h4>Summary</h4>
        <p>Total Questions: {detailedResult.result.length}</p>
        <p>Correct Answers: {detailedResult.result.filter(q => q.selectedAnswer === q.correctAnswer).length}</p>
        <p>Incorrect Answers: {detailedResult.result.filter(q => q.selectedAnswer && q.selectedAnswer !== q.correctAnswer).length}</p>
        <p>Skipped Questions: {detailedResult.result.filter(q => !q.selectedAnswer).length}</p>
      </div>
    </div>
  );
};

export default DetailedResult;
