import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import axios from 'axios';
import '../styles/ViewQuestions.css';

const ViewQuestions = () => {
  const { fileId } = useParams();
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const storageRef = ref(storage, `examFiles/${fileId}`);
        const downloadURL = await getDownloadURL(storageRef);
        const response = await axios.get(downloadURL); // Adjust this to match your backend API
        setQuestions(response.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, [fileId]);

  return (
    <div className="container">
      <h2>View Questions</h2>
      <ul>
        {questions.map((question, index) => (
          <li key={index}>{question}</li>
        ))}
      </ul>
    </div>
  );
};

export default ViewQuestions;
