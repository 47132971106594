/*import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this path is correct
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import '../styles/Results.css';

const Results = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        // Fetch results
        const resultsCollection = collection(db, 'results');
        const resultsSnapshot = await getDocs(resultsCollection);
        const resultsList = resultsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch courses
        const coursesCollection = collection(db, 'courses');
        const coursesSnapshot = await getDocs(coursesCollection);
        const coursesList = coursesSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().courseName;
          return acc;
        }, {});

        // Fetch subjects
        const subjectsCollection = collection(db, 'subjects');
        const subjectsSnapshot = await getDocs(subjectsCollection);
        const subjectsList = subjectsSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().subjectName;
          return acc;
        }, {});

        // Fetch students
        const studentsCollection = collection(db, 'studentDetails');
        const studentsSnapshot = await getDocs(studentsCollection);
        const studentsList = studentsSnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          acc[data.uid] = data.name;
          return acc;
        }, {});

        // Populate results with course names, subject names, and student names
        const populatedResults = resultsList.map(result => ({
          ...result,
          courseName: coursesList[result.course] || 'N/A',
          subjectName: subjectsList[result.subject] || 'N/A',
          studentName: studentsList[result.studentId] || 'N/A',
        }));

        setResults(populatedResults);
      } catch (error) {
        setError('Error fetching results: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'results', id));
      setResults(prevResults => prevResults.filter(result => result.id !== id));
    } catch (error) {
      setError('Error deleting result: ' + error.message);
    }
  };

  const handleDownload = async (studentId, examId, studentName) => {
    try {
      // Fetch the detailed result for this student and exam
      const resultsCollection = collection(db, 'results');
      const q = query(resultsCollection, where('studentId', '==', studentId), where('examId', '==', examId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const resultData = querySnapshot.docs[0].data();

        // Create PDF document
        const doc = new jsPDF();

        // Title
        doc.text(`Detailed Result for Student: ${studentName}`, 10, 10);

        // Add table headings
        const headers = ["Question ID", "Question", "Correct Answer", "Selected Answer", "Status", "Time Spent"];
        const data = resultData.result.map(questionResult => [
          questionResult.questionId,
          questionResult.questionText || 'N/A',
          questionResult.correctAnswer,
          questionResult.selectedAnswer,
          questionResult.selectedAnswer === questionResult.correctAnswer ? 'Correct' : 'Incorrect',
          questionResult.timeSpent
        ]);

        let finalY = 20;

        // Add table to PDF
        doc.autoTable({
          head: [headers],
          body: data,
          startY: finalY,
          theme: 'striped',
          margin: { top: 20 },
          didDrawPage: (data) => {
            // Keep track of the table's end position
            finalY = data.cursor.y;
          }
        });

         // Ensure summary is visible on the page
      const pageHeight = doc.internal.pageSize.height;
      const summaryHeight = 40; // Estimate space required for the summary
      const marginBottom = 10; // Margin at the bottom of the page

      // Calculate Y position for the summary
      let summaryY = finalY + 10; // Offset from table end

      // Check if summary fits on the current page
      if (summaryY + summaryHeight > pageHeight - marginBottom) {
        // Add a new page if needed
        doc.addPage();
        summaryY = 10; // Start at the top of the new page
      }

        // Add summary
        doc.text(`Total Questions: ${resultData.result.length}`, 10, summaryY);
        doc.text(`Correct Answers: ${resultData.result.filter(q => q.selectedAnswer === q.correctAnswer).length}`, 10, summaryY + 10);
        doc.text(`Incorrect Answers: ${resultData.result.filter(q => q.selectedAnswer && q.selectedAnswer !== q.correctAnswer).length}`, 10, summaryY + 20);
        doc.text(`Skipped Questions: ${resultData.result.filter(q => !q.selectedAnswer).length}`, 10, summaryY + 30);

        // Save the PDF
        doc.save(`Detailed_Result_${studentId}_${examId}.pdf`);
      } else {
        console.log('No detailed result found.');
      }
    } catch (error) {
      console.error('Error generating PDF: ', error);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="results">
      <h2>Exam Results</h2>
      <table>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Course</th>
            <th>Subject</th>
            <th>Marks Obtained</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {results.length > 0 ? (
            results.map(result => (
              <tr key={result.id}>
                <td>
                  <Link to={`/detailed-result/${result.studentId}/${result.examId}`}>
                    {result.studentName}
                  </Link>
                </td>
                <td>{result.courseName}</td>
                <td>{result.subjectName}</td>
                <td>{result.marksObtained !== undefined ? result.marksObtained : 'Pending'}</td>
                <td>{result.submittedAt ? new Date(result.submittedAt.toDate()).toLocaleDateString() : 'N/A'}</td>
                <td>
                  <button onClick={() => handleDelete(result.id)} className="delete-button">
                    Delete
                  </button>
                  <button
                    onClick={() => handleDownload(result.studentId, result.examId, result.studentName)}
                    className="download-button"
                  >
                    Download
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No results available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Results; */


import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this path is correct
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import '../styles/Results.css';

const Results = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOption, setSortOption] = useState('default');

  useEffect(() => {
    const fetchResults = async () => {
      try {
        // Fetch results
        const resultsCollection = collection(db, 'results');
        const resultsSnapshot = await getDocs(resultsCollection);
        const resultsList = resultsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch courses
        const coursesCollection = collection(db, 'courses');
        const coursesSnapshot = await getDocs(coursesCollection);
        const coursesList = coursesSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().courseName;
          return acc;
        }, {});

        // Fetch subjects
        const subjectsCollection = collection(db, 'subjects');
        const subjectsSnapshot = await getDocs(subjectsCollection);
        const subjectsList = subjectsSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().subjectName;
          return acc;
        }, {});

        // Fetch students
        const studentsCollection = collection(db, 'studentDetails');
        const studentsSnapshot = await getDocs(studentsCollection);
        const studentsList = studentsSnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          acc[data.uid] = data.name;
          return acc;
        }, {});

        // Populate results with course names, subject names, and student names
        const populatedResults = resultsList.map(result => ({
          ...result,
          courseName: coursesList[result.course] || 'N/A',
          subjectName: subjectsList[result.subject] || 'N/A',
          studentName: studentsList[result.studentId] || 'N/A',
        }));

        setResults(populatedResults);
      } catch (error) {
        setError('Error fetching results: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'results', id));
      setResults(prevResults => prevResults.filter(result => result.id !== id));
    } catch (error) {
      setError('Error deleting result: ' + error.message);
    }
  };

  const handleDownload = async (studentId, examId, studentName) => {
    try {
      // Fetch the detailed result for this student and exam
      const resultsCollection = collection(db, 'results');
      const q = query(resultsCollection, where('studentId', '==', studentId), where('examId', '==', examId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const resultData = querySnapshot.docs[0].data();

        // Create PDF document
        const doc = new jsPDF();

        // Title
        doc.text(`Detailed Result for Student: ${studentName}`, 10, 10);

        // Add table headings
        const headers = ["Question ID", "Question", "Correct Answer", "Selected Answer", "Status", "Time Spent"];
        const data = resultData.result.map(questionResult => [
          questionResult.questionId,
          questionResult.questionText || 'N/A',
          questionResult.correctAnswer,
          questionResult.selectedAnswer,
          questionResult.selectedAnswer === questionResult.correctAnswer ? 'Correct' : 'Incorrect',
          questionResult.timeSpent
        ]);

        let finalY = 20;

        // Add table to PDF
        doc.autoTable({
          head: [headers],
          body: data,
          startY: finalY,
          theme: 'striped',
          margin: { top: 20 },
          didDrawPage: (data) => {
            // Keep track of the table's end position
            finalY = data.cursor.y;
          }
        });

         // Ensure summary is visible on the page
      const pageHeight = doc.internal.pageSize.height;
      const summaryHeight = 40; // Estimate space required for the summary
      const marginBottom = 10; // Margin at the bottom of the page

      // Calculate Y position for the summary
      let summaryY = finalY + 10; // Offset from table end

      // Check if summary fits on the current page
      if (summaryY + summaryHeight > pageHeight - marginBottom) {
        // Add a new page if needed
        doc.addPage();
        summaryY = 10; // Start at the top of the new page
      }

        // Add summary
        doc.text(`Total Questions: ${resultData.result.length}`, 10, summaryY);
        doc.text(`Correct Answers: ${resultData.result.filter(q => q.selectedAnswer === q.correctAnswer).length}`, 10, summaryY + 10);
        doc.text(`Incorrect Answers: ${resultData.result.filter(q => q.selectedAnswer && q.selectedAnswer !== q.correctAnswer).length}`, 10, summaryY + 20);
        doc.text(`Skipped Questions: ${resultData.result.filter(q => !q.selectedAnswer).length}`, 10, summaryY + 30);

        // Save the PDF
        doc.save(`Detailed_Result_${studentId}_${examId}.pdf`);
      } else {
        console.log('No detailed result found.');
      }
    } catch (error) {
      console.error('Error generating PDF: ', error);
    }
  };

  const sortedResults = [...results].sort((a, b) => {
    switch (sortOption) {
      case 'date':
        return new Date(b.submittedAt?.toDate()) - new Date(a.submittedAt?.toDate());
      case 'course':
        return a.courseName.localeCompare(b.courseName);
      case 'subject':
        return a.subjectName.localeCompare(b.subjectName);
      default:
        return 0; // Default sorting
    }
  });
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="results">
      <h2>Exam Results</h2>

      <div className="sort-options">
        <label htmlFor="sort">Sort by: </label>
        <select
          id="sort"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="default">Default</option>
          <option value="date">Date</option>
          <option value="course">Course</option>
          <option value="subject">Subject</option>
        </select>
      </div>
      
      <table>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Course</th>
            <th>Subject</th>
            <th>Marks Obtained</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {results.length > 0 ? (
            results.map(result => (
              <tr key={result.id}>
                <td>
                  <Link to={`/detailed-result/${result.studentId}/${result.examId}`}>
                    {result.studentName}
                  </Link>
                </td>
                <td>{result.courseName}</td>
                <td>{result.subjectName}</td>
                <td>{result.marksObtained !== undefined ? result.marksObtained : 'Pending'}</td>
                <td>{result.submittedAt ? new Date(result.submittedAt.toDate()).toLocaleDateString() : 'N/A'}</td>
                <td>
                  <button onClick={() => handleDelete(result.id)} className="delete-button">
                    Delete
                  </button>
                  <button
                    onClick={() => handleDownload(result.studentId, result.examId, result.studentName)}
                    className="download-button"
                  >
                    Download
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No results available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Results;



