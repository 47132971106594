import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AdminDashboard.css'

function AdminDashboard() {
  const [activeButton, setActiveButton] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    switch (buttonId) {
      case 'add-new-exam':
        navigate('/add-new-exam');
        break;
      case 'add-new-course':
        navigate('/add-new-course');
        break;
      case 'add-subject':
        navigate('/add-subject');
        break;
      case 'exam-list':
        navigate('/exam-list');
        break;
      case 'question-list':
        navigate('/question-list');
        break;
      case 'student-list':
        navigate('/student-list');
        break;
      case 'results':
        navigate('/results');
        break;
      case 'blocked-users':
        navigate('/blocked-users');
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    // Perform any logout logic here (e.g., clear authentication tokens)
    navigate('/'); // Redirect to homepage
  };

  return (
    <div className="admin-dashboard">
      <header>
        <h1>IMTS INSTITUTE EXAM PORTAL</h1>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </header>
      <div className="container">
        <div className="row">
          <div className="col">
            <div
              className={`card ${activeButton === 'add-new-exam' ? 'active' : ''}`}
              onClick={() => handleButtonClick('add-new-exam')}
            >
              <div className="card-body">
                <i className="fas fa-plus"></i>
                <span>Add new exam</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card ${activeButton === 'add-subject' ? 'active' : ''}`}
              onClick={() => handleButtonClick('add-subject')}
            >
              <div className="card-body">
                <i className="fas fa-book"></i>
                <span>Add Subject</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card ${activeButton === 'add-new-course' ? 'active' : ''}`}
              onClick={() => handleButtonClick('add-new-course')}
            >
              <div className="card-body">
                <i className="fas fa-chalkboard-teacher"></i>
                <span>Add new course</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div
              className={`card ${activeButton === 'exam-list' ? 'active' : ''}`}
              onClick={() => handleButtonClick('exam-list')}
            >
              <div className="card-body">
                <i className="fas fa-list-ul"></i>
                <span>Exam List</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card ${activeButton === 'question-list' ? 'active' : ''}`}
              onClick={() => handleButtonClick('question-list')}
            >
              <div className="card-body">
                <i className="fas fa-th-list"></i>
                <span>Question List</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card ${activeButton === 'student-list' ? 'active' : ''}`}
              onClick={() => handleButtonClick('student-list')}
            >
              <div className="card-body">
                <i className="fas fa-graduation-cap"></i>
                <span>Student List</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card ${activeButton === 'results' ? 'active' : ''}`}
              onClick={() => handleButtonClick('results')}
            >
              <div className="card-body">
                <i className="fas fa-trophy"></i>
                <span>Results</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card ${activeButton === 'blocked-users' ? 'active' : ''}`}
              onClick={() => handleButtonClick('blocked-users')}
            >
              <div className="card-body">
                <i className="fas fa-ban"></i>
                <span>Blocked Users</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;


