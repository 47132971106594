import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import HomePage from './components/HomePage';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard'; 
import StudentLogin from './components/StudentLogin';
import ForgotPassword from './components/ForgotPassword';
import Signup from './components/Signup';
import ViewQuestions from './components/ViewQuestions';
import StudentList from './components/StudentList';
import StudentDashboard from './components/StudentDashboard';
import AddNewCourse from './components/AddNewCourse';
import AddNewExam from './components/AddNewExam';
import AddSubject from './components/AddSubject';
import ExamList from './components/ExamList';
import ExamDashboard from './components/ExamDashboard';
import Results from './components/Results';
import Header from './components/Header';
import Footer from './components/Footer';
import AlertTab from './components/AlertTab'
import BlockedUsers from './components/BlockedUsers';
import DetailedResult from './components/DetailedResult';
import QuestionList from './components/QuestionList';
import EditExcel from './components/EditExcel';

const App = () => {
  return (
    <Router>
      <Header />
      <main>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/student-login" element={<StudentLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/view-questions/:fileId" element={<ViewQuestions />} />
        <Route path="/student-list" element={<StudentList />} />
        <Route path="/student-dashboard" element={<StudentDashboard />} />
        <Route path="/add-new-course" element={<AddNewCourse />} />
        <Route path="/add-new-exam" element={<AddNewExam />} />
        <Route path="/add-subject" element={<AddSubject />} />
        <Route path="/exam-list" element={<ExamList />} />
        <Route path="/attempt-exam/:examId" element={<ExamDashboard />} />
        <Route path="/results" element={<Results />} />
        <Route path="/alert" element={<AlertTab />} />
        <Route path="/blocked-users" element={<BlockedUsers />} />
        <Route path="/detailed-result/:studentId/:examId" element={<DetailedResultWrapper />} />
        <Route path="/question-list" element={<QuestionList />} />
        <Route path="/edit-excel" element={<EditExcel />} />
      </Routes>
      </main>
      <Footer />
    </Router>
  );
}

const DetailedResultWrapper = () => {
  const { studentId, examId } = useParams();
  return <DetailedResult studentId={studentId} examId={examId} />;
};

export default App;
