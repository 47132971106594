/*import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/AddSubject.css';

const AddSubject = () => {
  const [courseId, setCourseId] = useState(''); // State to store courseId
  const [subjectName, setSubjectName] = useState('');
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [sortOption, setSortOption] = useState('');

  // Fetch courses from Firebase
  const fetchCourses = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'courses'));
      const coursesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCourses(coursesList);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Fetch subjects from Firebase filtered by courseId
  const fetchSubjects = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'subjects').where('courseId', '==', courseId));
      const subjectsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSubjects(subjectsList);
      // Store subjects in local storage
      localStorage.setItem('subjects', JSON.stringify(subjectsList));
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  // Fetch courses and subjects on component mount
  useEffect(() => {
    fetchCourses();
    // Retrieve subjects from local storage if available
    const storedSubjects = localStorage.getItem('subjects');
    if (storedSubjects) {
      setSubjects(JSON.parse(storedSubjects));
    } else {
      fetchSubjects();
    }
  }, [courseId]); // Update subjects whenever courseId changes

  // Handle form submission to add a new subject
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (courseId.trim() === '' || subjectName.trim() === '') {
      alert('Please select a course and fill in all fields');
      return;
    }
    try {
      const newSubjectRef = await addDoc(collection(db, 'subjects'), {
        courseId, // Add courseId to the subject document
        subjectName,
      });

      // Update the document to include the generated ID
      await updateDoc(doc(db, 'subjects', newSubjectRef.id), { id: newSubjectRef.id });

      // Update local state with the new subject
      const updatedSubjects = [...subjects, { id: newSubjectRef.id, courseId, subjectName }];
      setSubjects(updatedSubjects);
      // Update local storage with updated subjects
      localStorage.setItem('subjects', JSON.stringify(updatedSubjects));

      setSubjectName('');
      alert('Subject added successfully');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  // Handle deletion of a subject
  const handleDelete = async (subjectId) => {
    try {
      await deleteDoc(doc(db, 'subjects', subjectId));
      const updatedSubjects = subjects.filter(subject => subject.id !== subjectId);
      setSubjects(updatedSubjects);
      // Update local storage with updated subjects
      localStorage.setItem('subjects', JSON.stringify(updatedSubjects));
      alert('Subject deleted successfully');
    } catch (e) {
      console.error('Error deleting document: ', e);
    }
  };

  // Handle sorting subjects
  const handleSort = (option) => {
    setSortOption(option);
    const sortedSubjects = [...subjects];
    if (option === 'subjectName') {
      sortedSubjects.sort((a, b) => a.subjectName.localeCompare(b.subjectName));
    } else if (option === 'courseName') {
      sortedSubjects.sort((a, b) => {
        const courseA = courses.find(course => course.id === a.courseId)?.courseName || '';
        const courseB = courses.find(course => course.id === b.courseId)?.courseName || '';
        return courseA.localeCompare(courseB);
      });
    }
    setSubjects(sortedSubjects);
  };

  return (
    <div className="add-subject-page">
      <h2>Add New Subject</h2>
      <form className="add-subject-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="courseName">Course Name</label>
          <select
            id="courseName"
            name="courseName"
            value={courseId}
            onChange={(e) => setCourseId(e.target.value)}
            required
          >
            <option value="">Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.courseName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="subjectName">Subject Name</label>
          <input
            type="text"
            id="subjectName"
            name="subjectName"
            value={subjectName}
            onChange={(e) => setSubjectName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="add-subject-button">Add Subject</button>
      </form>

      <h2>Subjects List</h2>
      <div className="sort-options">
        <label htmlFor="sort">Sort By: </label>
        <select id="sort" value={sortOption} onChange={(e) => handleSort(e.target.value)}>
          <option value="">Select</option>
          <option value="subjectName">Subject Name</option>
          <option value="courseName">Course Name</option>
          
        </select>
      </div>
      <table className="subjects-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Course</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map(subject => (
            <tr key={subject.id}>
              <td>{subject.subjectName}</td>
              <td>{courses.find(course => course.id === subject.courseId)?.courseName}</td>
              
              <td>
                <button onClick={() => handleDelete(subject.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddSubject; */

import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/AddSubject.css';

const AddSubject = () => {
  const [courseId, setCourseId] = useState(''); // State to store courseId
  const [subjectName, setSubjectName] = useState('');
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [sortOption, setSortOption] = useState('');

  // Fetch courses from Firebase
  const fetchCourses = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'courses'));
      const coursesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCourses(coursesList);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Fetch subjects from Firebase filtered by courseId
  const fetchSubjects = async () => {
    try {
      if (courseId) {
        const q = query(collection(db, 'subjects'), where('courseId', '==', courseId));
        const querySnapshot = await getDocs(q);
        const subjectsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSubjects(subjectsList);
        // Store subjects in local storage (optional)
        localStorage.setItem('subjects', JSON.stringify(subjectsList));
      } else {
        setSubjects([]);
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  // Fetch courses on component mount
  useEffect(() => {
    fetchCourses();
  }, []);

  // Fetch subjects whenever courseId changes
  useEffect(() => {
    fetchSubjects();
  }, [courseId]);

  // Handle form submission to add a new subject
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (courseId.trim() === '' || subjectName.trim() === '') {
      alert('Please select a course and fill in all fields');
      return;
    }
    try {
      const newSubjectRef = await addDoc(collection(db, 'subjects'), {
        courseId, // Add courseId to the subject document
        subjectName,
      });

      // Update the document to include the generated ID
      await updateDoc(doc(db, 'subjects', newSubjectRef.id), { id: newSubjectRef.id });

      // Fetch subjects again after adding a new one
      fetchSubjects();

      setSubjectName('');
      alert('Subject added successfully');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  // Handle deletion of a subject
  const handleDelete = async (subjectId) => {
    try {
      await deleteDoc(doc(db, 'subjects', subjectId));
      const updatedSubjects = subjects.filter(subject => subject.id !== subjectId);
      setSubjects(updatedSubjects);
      // Update local storage with updated subjects
      localStorage.setItem('subjects', JSON.stringify(updatedSubjects));
      alert('Subject deleted successfully');
    } catch (e) {
      console.error('Error deleting document: ', e);
    }
  };

  // Handle sorting subjects
  const handleSort = (option) => {
    setSortOption(option);
    const sortedSubjects = [...subjects];
    if (option === 'subjectName') {
      sortedSubjects.sort((a, b) => a.subjectName.localeCompare(b.subjectName));
    } else if (option === 'courseName') {
      sortedSubjects.sort((a, b) => {
        const courseA = courses.find(course => course.id === a.courseId)?.courseName || '';
        const courseB = courses.find(course => course.id === b.courseId)?.courseName || '';
        return courseA.localeCompare(courseB);
      });
    }
    setSubjects(sortedSubjects);
  };

  return (
    <div className="add-subject-page">
      <h2>Add New Subject</h2>
      <form className="add-subject-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="courseName">Course Name</label>
          <select
            id="courseName"
            name="courseName"
            value={courseId}
            onChange={(e) => setCourseId(e.target.value)}
            required
          >
            <option value="">Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.courseName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="subjectName">Subject Name</label>
          <input
            type="text"
            id="subjectName"
            name="subjectName"
            value={subjectName}
            onChange={(e) => setSubjectName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="add-subject-button">Add Subject</button>
      </form>

      <h2>Subjects List</h2>
      <div className="sort-options">
        <label htmlFor="sort">Sort By: </label>
        <select id="sort" value={sortOption} onChange={(e) => handleSort(e.target.value)}>
          <option value="">Select</option>
          <option value="subjectName">Subject Name</option>
          <option value="courseName">Course Name</option>
        </select>
      </div>
      <table className="subjects-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Course</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map(subject => (
            <tr key={subject.id}>
              <td>{subject.subjectName}</td>
              <td>{courses.find(course => course.id === subject.courseId)?.courseName}</td>
              <td>
                <button onClick={() => handleDelete(subject.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddSubject;

