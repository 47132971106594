import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AlertTab.css';

const AlertTab = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/student-login');
  };

  return (
    <div className="alert-tab-overlay">
      <div className="alert-tab">
        <h2>Suspicious Activity Detected</h2>
        <p>You have been blocked due to suspicious activities. Please try contacting the admin.</p>
        <button onClick={handleRedirect}>Okay</button>
      </div>
    </div>
  );
};

export default AlertTab;
