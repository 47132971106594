import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/AddNewCourse.css';

const AddNewCourse = () => {
  const [courseName, setCourseName] = useState('');
  const [courses, setCourses] = useState([]);

  // Define fetchCourses outside of useEffect so it can be called in multiple places
  const fetchCourses = async () => {
    const querySnapshot = await getDocs(collection(db, 'courses'));
    const courses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCourses(courses);
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleCourseSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'courses'), {
        courseName,
      });

      // Update the document to include the generated ID
      await updateDoc(doc(db, 'courses', docRef.id), { id: docRef.id });

      setCourseName('');
      fetchCourses(); // Refresh the course list after adding a new course
      alert('Course added successfully');
    } catch (e) {
      console.error('Error adding course: ', e);
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      await deleteDoc(doc(db, 'courses', id));
      setCourses(courses.filter(course => course.id !== id));
      alert('Course deleted successfully');
    } catch (e) {
      console.error('Error deleting course: ', e);
    }
  };

  return (
    <div className="add-new-course-page">
      <h2>Add New Course</h2>
      <form className="course-form" onSubmit={handleCourseSubmit}>
        <div className="form-group">
          <h3>Course Name</h3> 
          <input
            type="text"
            id="courseName"
            name="courseName"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="add-course-button">Add Course</button>
      </form>

      <h3>Courses</h3>
      <div className="courses-container">
        {courses.map((course) => (
          <div key={course.id} className="course">
            <h4>{course.courseName}</h4>
            <button onClick={() => handleDeleteCourse(course.id)} className="delete-course-button">
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddNewCourse;
