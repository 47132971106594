import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { getDownloadURL } from 'firebase/storage'; // Import getDownloadURL for fetching file URLs
import '../styles/ExamList.css';

const ExamList = () => {
  const [exams, setExams] = useState([]);
  const [courses, setCourses] = useState({});
  const [subjects, setSubjects] = useState({});

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesCollection = await getDocs(collection(db, 'courses'));
      const coursesData = {};
      coursesCollection.forEach((courseDoc) => {
        coursesData[courseDoc.id] = courseDoc.data().courseName;
      });
      setCourses(coursesData);
    };

    const fetchSubjects = async () => {
      const subjectsCollection = await getDocs(collection(db, 'subjects'));
      const subjectsData = {};
      subjectsCollection.forEach((subjectDoc) => {
        subjectsData[subjectDoc.id] = subjectDoc.data().subjectName;
      });
      setSubjects(subjectsData);
    };

    const fetchExams = async () => {
      const examCollection = await getDocs(collection(db, 'exams'));
      const fetchedExams = examCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetching download URLs for uploaded Excel files
      const examsWithFiles = await Promise.all(fetchedExams.map(async (exam) => {
        if (exam.excelFileUrl) {
          try {
            const downloadUrl = await getDownloadURL(exam.excelFileUrl);
            return { ...exam, excelFileUrl: downloadUrl };
          } catch (error) {
            console.error('Error fetching file URL:', error);
            return exam; // Return the exam as is if URL fetching fails
          }
        }
        return exam; // Return the exam as is if no file URL is present
      }));

      setExams(examsWithFiles);
    };

    fetchCourses();
    fetchSubjects();
    fetchExams();
  }, []);

  const handleFileClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleDeleteExam = async (examId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this exam?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'exams', examId));
        setExams(exams.filter(exam => exam.id !== examId));
        alert('Exam deleted successfully.');
      } catch (error) {
        console.error('Error deleting exam:', error);
        alert('Failed to delete exam. Please try again.');
      }
    }
  };

  return (
    <div className="exam-list-page">
      <h2>Exam List</h2>
      <table className="exam-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Course</th>
            <th>Duration</th>
            <th>Exam Date</th>
            <th>Valid From</th>
            <th>Valid Till</th>
            <th>Uploaded File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {exams.map((exam) => (
            <tr key={exam.id}>
              <td>{subjects[exam.subjectId]}</td>
              <td>{courses[exam.courseId]}</td>
              <td>{exam.duration} minutes</td>
              <td>{exam.examDate}</td>
              <td>{exam.validFrom}</td>
              <td>{exam.validTill}</td>
              <td>
                {exam.excelFileUrl ? (
                  <button onClick={() => handleFileClick(exam.excelFileUrl)}>
                    {exam.excelFileName}
                  </button>
                ) : (
                  'No file uploaded'
                )}
              </td>
              <td>
                <button className="delete-button" onClick={() => handleDeleteExam(exam.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExamList;

