/*import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import "../styles/StudentLogin.css";

const StudentLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const q = query(
        collection(db, "studentDetails"),
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError("No user found with this email");
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const user = userDoc.data();

      await signInWithEmailAndPassword(auth, user.email, password);
      navigate("/student-dashboard");
    } catch (error) {
      setError("Invalid email or password");
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className="student-login-page">
      <h2>Student Login</h2>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="student-email">Email</label>
          <input
            type="email"
            id="student-email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="student-password">Password</label>
          <input
            type="password"
            id="student-password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit" className="login-button">
          Sign In
        </button>
        <div className="additional-options">
          <a href="/forgot-password" className="forgot-password">
            Forgot Password?
          </a>
          <a href="/signup" className="signup">
            Sign Up
          </a>
        </div>
      </form>
    </div>
  );
};

export default StudentLogin; */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import "../styles/StudentLogin.css";

const StudentLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    const normalizedEmail = email.toLowerCase(); // Normalize email to lowercase

    try {
      const q = query(
        collection(db, "studentDetails"),
        where("email", "==", normalizedEmail)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError("No user found with this email");
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const user = userDoc.data();

      await signInWithEmailAndPassword(auth, normalizedEmail, password);
      navigate("/student-dashboard");
    } catch (error) {
      setError("Invalid email or password");
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className="student-login-page">
      <h2>Student Login</h2>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="student-email">Email</label>
          <input
            type="email"
            id="student-email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="student-password">Password</label>
          <input
            type="password"
            id="student-password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit" className="login-button">
          Sign In
        </button>
        <div className="additional-options">
          <a href="/forgot-password" className="forgot-password">
            Forgot Password?
          </a>
          <a href="/signup" className="signup">
            Sign Up
          </a>
        </div>
      </form>
    </div>
  );
};

export default StudentLogin;

