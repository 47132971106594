import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "../firebase"; // Import auth and sendPasswordResetEmail from firebase.js
import "../styles/ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send password reset email using Firebase Authentication
      await sendPasswordResetEmail(auth, email);

      // Password reset email sent successfully
      setMessage("Password reset link sent to your email.");
    } catch (error) {
      console.error("Error sending reset link:", error);
      setMessage(
        "Failed to send reset link. Please check your email and try again."
      );
    }
  };

  return (
    <div className="forgot-password-page">
      <h2>Forgot Password</h2>
      <form className="forgot-password-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button className="submit-button" type="submit">Submit</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default ForgotPassword;
