/*import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import '../styles/StudentDashboard.css';

const StudentDashboard = () => {
  const [studentDetails, setStudentDetails] = useState({});
  const [course, setCourse] = useState({});
  const [eligibleExams, setEligibleExams] = useState([]);
  const [subjects, setSubjects] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const studentDetailsQuery = query(
            collection(db, 'studentDetails'),
            where('email', '==', currentUser.email)
          );
          const studentDetailsSnapshot = await getDocs(studentDetailsQuery);
          const studentDetailsData = studentDetailsSnapshot.docs[0].data();
          setStudentDetails(studentDetailsData);
        }
      } catch (error) {
        console.error('Error fetching student details:', error);
      }
    };

    fetchStudentDetails();
  }, []);

  useEffect(() => {
    if (studentDetails.courseId) {
      const fetchCourse = async () => {
        try {
          const courseQuery = query(collection(db, 'courses'), where('id', '==', studentDetails.courseId));
          const courseSnapshot = await getDocs(courseQuery);
          const courseData = courseSnapshot.docs[0].data();
          setCourse(courseData);
        } catch (error) {
          console.error('Error fetching course:', error);
        }
      };

      fetchCourse();
    }
  }, [studentDetails]);

  useEffect(() => {
    if (course.id) {
      const fetchEligibleExams = async () => {
        try {
          const eligibleExamsQuery = query(collection(db, 'exams'), where('courseId', '==', course.id));
          const eligibleExamsSnapshot = await getDocs(eligibleExamsQuery);
          const currentTimestamp = new Date().getTime();
          const eligibleExamsData = eligibleExamsSnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(exam => {
              const examValidTill = new Date(exam.validTill).getTime();
              if (currentTimestamp > examValidTill) {
                // If the exam is past its valid till date, delete it
                deleteDoc(doc(db, 'exams', exam.id));
                return false;
              }
              return true;
            });
          setEligibleExams(eligibleExamsData);
        } catch (error) {
          console.error('Error fetching eligible exams:', error);
        }
      };

      fetchEligibleExams();
    }
  }, [course]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const subjectsSnapshot = await getDocs(collection(db, 'subjects'));
        const subjectsData = {};
        subjectsSnapshot.docs.forEach((doc) => {
          subjectsData[doc.id] = doc.data().subjectName;
        });
        setSubjects(subjectsData);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/student-login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleAttemptExam = async (examId) => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const resultsQuery = query(
          collection(db, 'results'),
          where('studentId', '==', currentUser.uid),
          where('examId', '==', examId)
        );
        const resultsSnapshot = await getDocs(resultsQuery);
        if (!resultsSnapshot.empty) {
          alert('You have already submitted this exam and cannot reattempt it.');
        } else {
          navigate(`/attempt-exam/${examId}`);
        }
      }
    } catch (error) {
      console.error('Error checking if exam already submitted:', error);
    }
  };

  return (
    <div className="student-dashboard">
      <h1>Student Dashboard</h1>
      <p>Welcome, {studentDetails.name}!</p>
      <button onClick={handleLogout}>Logout</button>
      <h2>Available Exams</h2>
      <table className="exams-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Duration</th>
            <th>Exam Date</th>
            <th>Valid From</th>
            <th>Valid Till</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {eligibleExams.map(exam => (
            <tr key={exam.id}>
              <td>{subjects[exam.subjectId] || 'Loading...'}</td>
              <td>{exam.duration} minutes</td>
              <td>{new Date(exam.examDate).toLocaleDateString()}</td>
              <td>{new Date(exam.validFrom).toLocaleString()}</td>
              <td>{new Date(exam.validTill).toLocaleString()}</td>
              <td>
                <button onClick={() => handleAttemptExam(exam.id)}>Attempt Exam</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentDashboard; */


import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import '../styles/StudentDashboard.css';
import InstructionsModal from './InstructionsModal';

const StudentDashboard = () => {
  const [studentDetails, setStudentDetails] = useState({});
  const [course, setCourse] = useState({});
  const [eligibleExams, setEligibleExams] = useState([]);
  const [subjects, setSubjects] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const studentDetailsQuery = query(
            collection(db, 'studentDetails'),
            where('email', '==', currentUser.email)
          );
          const studentDetailsSnapshot = await getDocs(studentDetailsQuery);
          const studentDetailsData = studentDetailsSnapshot.docs[0].data();
          setStudentDetails(studentDetailsData);
        }
      } catch (error) {
        console.error('Error fetching student details:', error);
      }
    };

    fetchStudentDetails();
  }, []);

  useEffect(() => {
    if (studentDetails.courseId) {
      const fetchCourse = async () => {
        try {
          const courseQuery = query(collection(db, 'courses'), where('id', '==', studentDetails.courseId));
          const courseSnapshot = await getDocs(courseQuery);
          const courseData = courseSnapshot.docs[0].data();
          setCourse(courseData);
        } catch (error) {
          console.error('Error fetching course:', error);
        }
      };

      fetchCourse();
    }
  }, [studentDetails]);

  useEffect(() => {
    if (course.id) {
      const fetchEligibleExams = async () => {
        try {
          const eligibleExamsQuery = query(collection(db, 'exams'), where('courseId', '==', course.id));
          const eligibleExamsSnapshot = await getDocs(eligibleExamsQuery);
          const currentTimestamp = new Date().getTime();
          const eligibleExamsData = eligibleExamsSnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(exam => {
              const examValidTill = new Date(exam.validTill).getTime();
              if (currentTimestamp > examValidTill) {
                // If the exam is past its valid till date, delete it
                deleteDoc(doc(db, 'exams', exam.id));
                return false;
              }
              return true;
            });
          setEligibleExams(eligibleExamsData);
        } catch (error) {
          console.error('Error fetching eligible exams:', error);
        }
      };

      fetchEligibleExams();
    }
  }, [course]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const subjectsSnapshot = await getDocs(collection(db, 'subjects'));
        const subjectsData = {};
        subjectsSnapshot.docs.forEach((doc) => {
          subjectsData[doc.id] = doc.data().subjectName;
        });
        setSubjects(subjectsData);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/student-login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleAttemptExam = async (examId) => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const resultsQuery = query(
          collection(db, 'results'),
          where('studentId', '==', currentUser.uid),
          where('examId', '==', examId)
        );
        const resultsSnapshot = await getDocs(resultsQuery);
        if (!resultsSnapshot.empty) {
          alert('You have already submitted this exam and cannot reattempt it.');
        } else {
          navigate(`/attempt-exam/${examId}`);
        }
      }
    } catch (error) {
      console.error('Error checking if exam already submitted:', error);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="student-dashboard">
      <h1>Student Dashboard</h1>
      <p>Welcome, {studentDetails.name}!</p>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      <button className="instructions-button" onClick={toggleModal}>Instructions</button>

      <InstructionsModal isOpen={isModalOpen} onClose={toggleModal} />
      <h2>Available Exams</h2>
      <table className="exams-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Duration</th>
            <th>Exam Date</th>
            <th>Valid From</th>
            <th>Valid Till</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {eligibleExams.map(exam => (
            <tr key={exam.id}>
              <td>{subjects[exam.subjectId] || 'Loading...'}</td>
              <td>{exam.duration} minutes</td>
              <td>{new Date(exam.examDate).toLocaleDateString()}</td>
              <td>{new Date(exam.validFrom).toLocaleString()}</td>
              <td>{new Date(exam.validTill).toLocaleString()}</td>
              <td>
                <button onClick={() => handleAttemptExam(exam.id)}>Attempt Exam</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentDashboard;

