import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import according to your Firebase setup
import '../styles/BlockedUsers.css'; // Create a separate CSS file for styling

function BlockedUsers() {
  const [blockedUsers, setBlockedUsers] = useState([]);

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blockedUsers'));
        const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setBlockedUsers(usersData);
      } catch (error) {
        console.error('Error fetching blocked users:', error);
      }
    };

    fetchBlockedUsers();
  }, []);

  const handleUnblock = async (userId) => {
    try {
      // Remove the blocked user entry from Firestore
      await deleteDoc(doc(db, 'blockedUsers', userId));
      // Update the local state to remove the user
      setBlockedUsers(blockedUsers.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  };

  return (
    <div className="blocked-users">
      <h2>Blocked Users</h2>
      <table className="blocked-users-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {blockedUsers.map(user => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>
                <button onClick={() => handleUnblock(user.id)}>Unblock</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BlockedUsers;
